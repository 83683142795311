import demo from "../../assets/images/client/demo.png"

const List = [
    {
        id: 1,
        name: "Name Of the Hospital ",
        title: "Specialist",
        category: "Hospital Cat",
        description: "In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content. Lorem ipsum may be used as a placeholder before the final copy is available.",
        date: "01-01-2021",
        website: "#",
        client_name: "John Doe",
        client_feedback: "In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content. Lorem ipsum may be used as a placeholder before the final copy is available.",
        tools: "Figma",        
        image: demo,
    },    
]

export default List;
