// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from "@firebase/firestore";


const firebaseConfig = {
  apiKey: "AIzaSyBq_3ox_QG3KVhHWmSIfYx74tL0f_k8FA8",
  authDomain: "personal-profile-4a5e3.firebaseapp.com",
  projectId: "personal-profile-4a5e3",
  storageBucket: "personal-profile-4a5e3.appspot.com",
  messagingSenderId: "139278090503",
  appId: "1:139278090503:web:7f288ae7689459991805a2",
  measurementId: "G-61X5REXNXC"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

export const db = getFirestore(app);
