import React from "react";
import {Link} from "react-router-dom";

import demo from "../../assets/images/client/demo.png"

class Clients extends React.Component{
    render() {
        return(
            <>
                <div className="row mt30">
                    <div className="col-lg-12">
                        <h2 className="sectionTitle">Working With</h2>
                        <div className="row">
                            <div className="col-md-4">
                                <Link to="/">
                                    <img src={demo} alt="Client" />
                                </Link>
                            </div>
                            <div className="col-md-4">
                                <Link to="/">
                                    <img src={demo} alt="Client" />
                                </Link>
                            </div>
                            <div className="col-md-4">
                                <Link to="/">
                                    <img src={demo} alt="Client" />
                                </Link>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-4">
                                <Link to="/">
                                    <img src={demo} alt="Client" />
                                </Link>
                            </div>
                            <div className="col-md-4">
                                <Link to="/">
                                    <img src={demo} alt="Client" />
                                </Link>
                            </div>
                            <div className="col-md-4">
                                <Link to="/">
                                    <img src={demo} alt="Client" />
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default Clients;
